// src/hooks/useRestoreSidebar.ts

import { useCallback } from "react";
import useSidebarStore from "@/common/store/sidebar";
import useClearMapData from "../../../hooks/useClearMapData";

const useRestoreSidebar = () => {
  const { setIsSidebarClosed, setIsSecondSidebarClosed } = useSidebarStore();
  const handleClearImagesAndLayers = useClearMapData();

  return useCallback(() => {
    setIsSidebarClosed(false);
    setIsSecondSidebarClosed(true);
    handleClearImagesAndLayers();
  }, [
    setIsSidebarClosed,
    setIsSecondSidebarClosed,
    handleClearImagesAndLayers,
  ]);
};

export default useRestoreSidebar;
