"use client";

import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import useRestoreSidebar from "../hooks/useRestoreSidebar";
import { determineRedirectUrl } from "@/utils/common";
import useIsActivePage from "@/common/components/SideNavigation/hooks/useIsActivePage";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/tooltip";
import React from "react";

const SideNavigationItem = ({ sidebarItem }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const restoreSidebar = useRestoreSidebar();
  const isActivePage = useIsActivePage();

  const handleSidebarItemClick = (e, sidebarItem, searchParams) => {
    e.preventDefault();

    const href = determineRedirectUrl(sidebarItem, searchParams);
    router.push(href, { scroll: false });

    restoreSidebar();
  };

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>
          <Link
            href={sidebarItem.href}
            className={`sidebar-item-link ${isActivePage(sidebarItem.title.toLowerCase()) ? "sidebar-item-link-active" : ""}`}
            scroll={false}
            onClick={(e) =>
              handleSidebarItemClick(e, sidebarItem, searchParams)
            }
          >
            <div className="flex flex-col items-center">
              {sidebarItem.icon}
              {/*<span className="sidebar-item-text">{sidebarItem.title}</span>*/}
            </div>
          </Link>
        </TooltipTrigger>
        <TooltipContent
          side="right"
          className="bg-primary-500 text-white border-none"
        >
          <span className="text-md font-medium">{sidebarItem.title}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default SideNavigationItem;
