import { useEffect, useState } from "react";
import { DefaultApiResponse, MyDataApiResponse } from "@repo/common/interfaces";
import fetchAPI from "@repo/common/helpers/fetch";
import Image from "next/image";
import { IconCheck, IconShoppingCart, IconTrash } from "@tabler/icons-react";
import { Button } from "@repo/ui/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { BoundingBox, Category } from "@repo/common/dto";
import createToast from "@repo/common/utils/toast";
import { ToastType } from "@repo/common/components/Toast";

export interface Product {
  id: number;
  workspace: string;
  store: string;
  title: string;
  desc: string;
  boundingBox: BoundingBox;
  overlayUrl: string;
  thumbnailUrl: string;
  category: string;
  date: string;
  user_id: string;
  price: number;
  stringFootprint: string[];
}

export interface CartItem {
  cartItemId: string;
  categoryId: string;
  quantity: number;
  status: string;
  product: Product;
}

export interface Cart {
  cartId: string;
  cartItems: CartItem[];
}

interface CartApiResponse<T> extends DefaultApiResponse {
  data: T;
}

const CheckoutConfirmation = ({
  carts,
  categories,
  selectedCategoryId,
  setSelectedCategoryId,
  onConfirm,
}) => {
  const prefixUrl =
    process.env.NODE_ENV === "development"
      ? process.env.NEXT_PUBLIC_SUBSCRIBE_SERVER_API
      : process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN;

  const executePurchase = async () => {
    const response = await fetchAPI<CartApiResponse<any>>(
      `${prefixUrl}/api/carts/items-payment/processing/${selectedCategoryId}?cartItemIds=${carts
        .map((cart) => cart.cartItemId)
        .join(",")}`,
    );

    if (response.result) {
      createToast(ToastType.SUCCESS, "Purchase successful");
    }

    onConfirm();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className="text-center"
    >
      <h2 className="text-xl font-semibold mb-8">Select Folder</h2>

      {/*<h2 className="text-2xl font-bold mb-4">Thank you for your purchase!</h2>*/}
      {/*<p className="mb-4">Your order has been successfully processed.</p>*/}
      <div className="grid grid-cols-2 gap-4 mb-6 auto-rows-fr">
        {categories.map((category) => (
          <div
            key={category.categoryId}
            className={`p-4 border rounded-lg ${
              selectedCategoryId === category.categoryId ? "bg-gray-200" : ""
            } cursor-pointer transition-colors duration-200 flex items-center justify-center`}
            onClick={() => {
              // console.log("Selected category:", category);
              setSelectedCategoryId(category.categoryId);
            }}
          >
            <h3 className="font-semibold">{category.name}</h3>
            {/*<p className="text-sm text-gray-600">{category.description}</p>*/}
          </div>
        ))}
      </div>
      <Button className="w-full flex gap-2" onClick={executePurchase}>
        <IconCheck size={16} />
        <span className="mr-2">Confirm</span>
      </Button>
    </motion.div>
  );
};

const CartItems = ({
  carts,
  totalPrice,
  deleteCartItem,
  handleCheckout,
  purchaseLoading,
}) => {
  if (!carts || carts.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="text-center text-gray-600 flex flex-col gap-4 justify-center items-center h-full"
      >
        <IconShoppingCart size={64} />
        <p className="text-lg">Your cart is empty</p>
        {/*<Link*/}
        {/*  href="/"*/}
        {/*  className="text-blue-500 hover:underline mt-4 inline-block"*/}
        {/*>*/}
        {/*  Continue Shopping*/}
        {/*</Link>*/}
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ul className="space-y-4 custom-scrollbar overflow-y-auto overflow-x-hidden w-[310px] h-[600px]">
        {carts &&
          carts.length !== 0 &&
          carts.map((cartItem) => (
            <li
              key={cartItem.cartItemId}
              className="flex items-center gap-4 border shadow-sm p-2 rounded-md"
            >
              <Image
                width={80}
                height={80}
                src={`${process.env.NEXT_PUBLIC_IMAGE_UPLOAD_SERVER_API_URL}/static${cartItem.product.thumbnailUrl}`}
                alt={cartItem.product.title}
                className="size-[80px] rounded object-cover"
              />

              <div className="flex-grow">
                <h3 className="text-sm text-gray-900 w-[140px] break-words line-clamp-2">
                  {cartItem.product.title}
                </h3>

                <dl className="mt-0.5 space-y-px text-[10px] text-gray-600">
                  <div>
                    <dt className="inline">Category:</dt>
                    <dd className="inline">{cartItem.product.category}</dd>
                  </div>
                  <div>
                    <dt className="inline">Date:</dt>
                    <dd className="inline">{cartItem.product.date}</dd>
                  </div>
                </dl>
              </div>

              <div className="flex flex-1 flex-grow items-center justify-center gap-1">
                <button
                  className="text-gray-600 transition hover:text-red-600"
                  onClick={() => deleteCartItem(cartItem.cartItemId)}
                >
                  <span className="sr-only">Remove item</span>
                  <IconTrash size={16} />
                </button>
              </div>
            </li>
          ))}
      </ul>
      <div className="mt-8 flex justify-end border-t border-gray-100 pt-8">
        <div className="w-screen max-w-lg space-y-4">
          <dl className="space-y-0.5 text-sm text-gray-700">
            <div className="flex justify-between !text-base font-medium">
              <dt>Total</dt>
              <dd>${totalPrice.toFixed(2)}</dd>
            </div>
          </dl>
          <div className="flex justify-end">
            <Button onClick={handleCheckout} disabled={purchaseLoading}>
              {purchaseLoading ? "Processing..." : "Checkout"}
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const ShoppingCartContents = ({ onClose }: { onClose: () => void }) => {
  const [carts, setCarts] = useState<CartItem[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isCheckout, setIsCheckout] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");

  const prefixUrl =
    process.env.NODE_ENV === "development"
      ? process.env.NEXT_PUBLIC_SUBSCRIBE_SERVER_API
      : process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN;

  const fetchCarts = async () => {
    const response = await fetchAPI<CartApiResponse<Cart>>(
      `${prefixUrl}/api/carts/get-my-cart`,
    );

    if (response.result) {
      setCarts(response.data.cartItems);
      calculateTotalPrice(response.data.cartItems);
    }
  };

  const deleteCartItem = async (cartItemId: string) => {
    const response = await fetchAPI<CartApiResponse<boolean>>(
      `${prefixUrl}/api/carts/delete-item/${cartItemId}`,
      { method: "DELETE" },
    );

    if (response.result) {
      fetchCarts();
    }
  };

  const fetchUsersCategories = async () => {
    try {
      const response = await fetchAPI<MyDataApiResponse<Category>>(
        `${prefixUrl}/api/my-data/categories/profile`,
      );

      if (response.result) {
        setCategories(response.data.items);
      }
    } catch (error) {
      console.error("Error fetching user categories:", error);
    }
  };

  const handleCheckout = async () => {
    setPurchaseLoading(true);
    await fetchUsersCategories();
    // Here you can add additional logic to handle the checkout process
    // For example, you might want to use the fetched categories
    // console.log("User categories:", categories);
    setIsCheckout(true);
    setPurchaseLoading(false);
  };

  useEffect(() => {
    fetchCarts();
  }, []);

  const calculateTotalPrice = (items: CartItem[]) => {
    if (!items || items.length === 0) {
      return;
    }
    const total = items.reduce(
      (sum, item) => sum + item.product.price * item.quantity,
      0,
    );
    setTotalPrice(total);
  };

  return (
    <div className="mx-auto max-w-screen-xl px-2 py-4 sm:px-3 sm:py-6 lg:px-4">
      <div className="mx-auto max-w-3xl">
        <div className="mt-8">
          <AnimatePresence mode="wait">
            {carts && carts?.length === 0 && !isCheckout ? (
              <motion.div
                key="empty"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="text-center text-gray-600 flex flex-col gap-4 justify-center items-center h-full"
              >
                <IconShoppingCart size={64} />
                <p className="text-lg">Your cart is empty</p>
                {/*<Link*/}
                {/*  href="/"*/}
                {/*  className="text-blue-500 hover:underline mt-4 inline-block"*/}
                {/*>*/}
                {/*  Continue Shopping*/}
                {/*</Link>*/}
              </motion.div>
            ) : isCheckout ? (
              <CheckoutConfirmation
                key="checkout"
                carts={carts}
                categories={categories}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
                onConfirm={onClose}
              />
            ) : (
              <CartItems
                carts={carts}
                totalPrice={totalPrice}
                deleteCartItem={deleteCartItem}
                handleCheckout={handleCheckout}
                purchaseLoading={purchaseLoading}
                key="cart"
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartContents;
