'use client'

import { Loader } from '@repo/common/Loading'
import { UserInfoResponse } from '@repo/common/dto'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@repo/ui/components/ui/avatar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@repo/ui/components/ui/popover'
import React, { useState } from 'react'

const SideNavigationUserInfo = ({
  handleLogout,
  user,
}: {
  handleLogout: () => Promise<void>
  user: UserInfoResponse['data']['items'][0]
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onLogout = async () => {
    setIsLoading(true)
    await handleLogout()
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Avatar className="size-8 my-2 bg-gray-100">
          <AvatarImage src={user.image} />
          <AvatarFallback>{user.name.slice(0, 2)}</AvatarFallback>
        </Avatar>
      </PopoverTrigger>
      <PopoverContent align="start">
        <div className="flex flex-col gap-4 justify-between">
          <div className="flex gap-1 justify-start items-center">
            <Avatar>
              <AvatarImage src={user.image} />
              <AvatarFallback>{user.name.slice(0, 2)}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col gap-1">
              <span className="justify-start text-md">{user.name}</span>
              <span className="justify-start text-xs">{user.email}</span>
            </div>
          </div>

          <button
            onClick={onLogout}
            className={`cursor-pointer rounded-lg bg-palette-red px-4 py-2 text-xs font-medium text-white flex justify-center items-center ${
              isLoading && 'opacity-80 cursor-not-allowed'
            }`}
            disabled={isLoading}
          >
            {isLoading ? <Loader style="size-4" /> : 'Logout'}
          </button>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default SideNavigationUserInfo
