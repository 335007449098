import { NotificationService } from '../service/Notification.service'
import { INotificationService } from '../service/Notification.service.interface'
import { AlarmBell, AllChecked, Close, MapSearch, Photo } from '@repo/ui/icons'
import { AnimatePresence, motion } from 'framer-motion'

import RedirectButton from '@/common/components/Buttons/RedirectButton'
import TextTabs from '@/common/components/Tabs/TextTabs'

export class NotificationComponent {
  notificationService: INotificationService

  constructor() {
    this.notificationService = new NotificationService()
  }

  Button = () => {
    return (
      <div className="flex flex-col items-center px-2 py-px">
        <AlarmBell width={20} height={20} />
        {/*<span className="sidebar-item-text">Noti.</span>*/}
      </div>
    )
  }

  AllCheckedInfo = () => {
    return (
      <div className="flex size-full flex-col items-center justify-center text-black">
        <AllChecked />
        You&apos;ve seen all notifications!
      </div>
    )
  }

  NoData = ({
    setIsNotiOpen,
    clearSelectedImages,
    clearSelectedLumirImages,
  }) => {
    return (
      <motion.div
        initial={{ scale: 0, transformOrigin: 'bottom left' }}
        animate={{ scale: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{ type: 'spring', stiffness: 200, damping: 20 }}
        className="flex h-full flex-col items-center justify-center gap-2 text-black"
      >
        <h1 className="text-lg font-semibold">There&apos;s No Notifications</h1>
        <RedirectButton
          icon={<MapSearch />}
          content={'Subscribe Images!'}
          url="searched-data"
          onClick={() => {
            setIsNotiOpen()
            clearSelectedImages()
            clearSelectedLumirImages()
          }}
        />
      </motion.div>
    )
  }

  Content = ({
    router,
    setIsSecondSidebarClosed,
    isNotiOpen,
    notiRef,
    setIsNotiOpen,
    isNotiAllChecked,
    setIsNotiAllChecked,
    notifications,
    clearSelectedImages,
    clearSelectedLumirImages,
  }) => {
    return (
      <AnimatePresence>
        {isNotiOpen && (
          <motion.div
            initial={{ scale: 0, transformOrigin: 'bottom left' }}
            animate={{ scale: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 200, damping: 20 }}
            ref={notiRef}
            className="absolute bottom-0 left-16 h-[450px] w-[551px] cursor-default rounded-lg border border-gray-200 bg-white shadow-xl"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className="relative flex h-full flex-col items-start justify-start">
              <header className="flex w-full justify-between p-6 pb-10 pr-2 text-black">
                <h1 className="text-left font-bold">Notifications</h1>
                <Close onClick={setIsNotiOpen} />
              </header>
              <div className="relative mx-auto flex px-2">
                <TextTabs
                  tabItems={[
                    { title: 'All', url: '#' },
                    { title: 'Subscribe', url: '#' },
                  ]}
                />
                <label
                  htmlFor="AcceptConditions"
                  className={`relative right-12 h-6 w-10 cursor-pointer rounded-full transition [-webkit-tap-highlight-color:_transparent] ${isNotiAllChecked ? 'bg-palette-green' : 'bg-gray-300'}`}
                >
                  <input
                    type="checkbox"
                    id="AcceptConditions"
                    className="peer sr-only"
                    checked={isNotiAllChecked}
                    onChange={(e) => {
                      setIsNotiAllChecked(e.target.checked)
                    }}
                  />
                  <span
                    className="absolute inset-y-0 start-0 m-1 size-4 rounded-full bg-white transition-all"
                    style={{
                      transform: isNotiAllChecked
                        ? 'translateX(1rem)'
                        : 'translateX(0rem)',
                    }}
                  />
                </label>
              </div>
              {
                <div
                  key="all"
                  className="custom-scrollbar w-full flex-1 overflow-hidden overflow-y-auto pb-6"
                >
                  {isNotiAllChecked
                    ? notificationComponent.AllCheckedInfo()
                    : notificationComponent.List({
                        router,
                        notifications,
                        setIsNotiOpen,
                        setIsSecondSidebarClosed,
                        clearSelectedImages,
                        clearSelectedLumirImages,
                      })}
                </div>
              }
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    )
  }

  List = ({
    router,
    notifications,
    setIsNotiOpen,
    setIsSecondSidebarClosed,
    clearSelectedImages,
    clearSelectedLumirImages,
  }) => {
    if (notifications.length === 0) {
      return (
        <this.NoData
          setIsNotiOpen={setIsNotiOpen}
          clearSelectedImages={clearSelectedImages}
          clearSelectedLumirImages={clearSelectedLumirImages}
        />
      )
    }

    return (
      // 왼쪽에서 서서히 나오는 motion.div
      <motion.div
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -20, opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col gap-2 p-4"
      >
        {notifications.map((notification) => {
          return (
            <div
              key={notification.id}
              className="flex max-h-[70px] flex-1 cursor-pointer items-center justify-between rounded-lg border px-4 py-2 text-black shadow-lg"
              onClick={() => {
                // TODO: dto 생성 필요
                const newSearchParams = new URLSearchParams()
                newSearchParams.set(
                  'startDate',
                  notification.startDate.toString()
                    ? notification.startDate.split('T')[0]
                    : notification.startDate + 'T00:00:00Z'
                )
                newSearchParams.set(
                  'endDate',
                  notification.endDate.toString()
                    ? notification.endDate.split('T')[0]
                    : notification.endDate + 'T00:00:00Z'
                )
                newSearchParams.set('beamMode', notification.beamMode)
                newSearchParams.set('polarization', notification.polarization)
                newSearchParams.set(
                  'flightDirection',
                  notification.flightDirection
                )
                newSearchParams.set(
                  'upperLeftLat',
                  notification.upperLeftLat.toString()
                )
                newSearchParams.set(
                  'upperLeftLng',
                  notification.upperLeftLng.toString()
                )
                newSearchParams.set(
                  'lowerRightLat',
                  notification.lowerRightLat.toString()
                )
                newSearchParams.set(
                  'lowerRightLng',
                  notification.lowerRightLng.toString()
                )
                newSearchParams.set(
                  'pageSize',
                  notification.pageSize.toString()
                )
                // newSearchParams.set(
                //   'firstFrame',
                //   notification.firstFrame.toString()
                // )
                // newSearchParams.set('track', notification.track.toString())

                // console.log(newSearchParams.toString());
                router.push(`/searched-data?${newSearchParams}`, {
                  scroll: false,
                })
                setIsSecondSidebarClosed(true)
                setIsNotiOpen(false)
              }}
            >
              <span className="flex items-center">
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <Photo />
                    <span className="text-[14px] font-bold">
                      You have subscribed to a Frame
                    </span>
                  </div>
                  <div className="flex items-center gap-2 pl-7">
                    <span className="text-[12px]">
                      You have subscribed to a Frame
                    </span>
                    <span className="rounded-md border bg-primary-500 px-1 py-px text-[14px] font-bold text-white transition duration-200 hover:bg-primary-600 active:bg-primary-700">
                      {notification.firstFrame}
                    </span>
                    <span className="text-[12px]">Track</span>
                    <span className="rounded-md border bg-primary-500 px-1 py-px text-[14px] font-bold text-white transition duration-200 hover:bg-primary-600 active:bg-primary-700">
                      {notification.track}
                    </span>
                  </div>
                </div>
              </span>
              <div className="flex justify-end text-xs text-gray-400">
                {this.notificationService.getTimeAgo(notification.updatedAt)}
              </div>
            </div>
          )
        })}
      </motion.div>
    )
  }
}

export const notificationComponent = new NotificationComponent()
