'use client'

import { bottomSidebarItems, sidebarItems } from '@repo/common/constants'
import { Separator } from '@repo/ui/components/ui/separator'
import React from 'react'

import SideNavigationItems from './components/SideNavigationItems'
import SideNavigationLogo from './components/SideNavigationLogo'
import SideNavigationRouteToggle from './components/SideNavigationRouteToggle'
import SideNavigationShoppingPopup from '@/common/components/SideNavigation/components/ShoppingCart/ShoppingCartPopup'
import SideNavigationUserContent from '@/common/components/SideNavigation/components/SideNavigationUserContent'

const SideNavigation = () => {
  return (
    <aside className="relative z-50 flex h-screen w-16 flex-col justify-between bg-primary">
      <div>
        <SideNavigationLogo />
        <SideNavigationItems sidebarItems={sidebarItems} />
      </div>

      {/* // ? 알림 정보 + 사용자 정보 */}
      <div className="bottom-0 flex flex-col items-center justify-center gap-2 px-2">
        <Separator className="bg-gray-700" />

        {/*{process.env.NODE_ENV === "development" && (*/}
        <SideNavigationRouteToggle sidebarItem={bottomSidebarItems[0]} />
        {/*)}*/}
        <SideNavigationShoppingPopup />
        <SideNavigationUserContent />
      </div>
    </aside>
  )
}

export default SideNavigation
