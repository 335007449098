import { useState, useRef, useCallback, useEffect } from "react";

const useTogglePopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  // 내부 팝업과 외부 버튼에 대한 참조
  const innerRef = useRef(null);
  const outerRef = useRef(null);

  // 클릭 이벤트가 외부 버튼 또는 내부 팝업 외부에서 발생하면 팝업을 닫음
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        innerRef.current &&
        outerRef.current &&
        !innerRef.current.contains(event.target) &&
        !outerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen, innerRef, outerRef]);

  // Escape 키를 누르면 팝업을 닫음
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [isOpen]);

  return { isOpen, toggle, innerRef, outerRef };
};

export default useTogglePopup;
