import Cookies from "js-cookie";
// import { ApiResponse } from "!/interfaces";

const fetchAPI = async <T>(
  API_URL: string,
  options: RequestInit = {},
  serverAccessToken?: string,
): Promise<T> => {
  let accessToken: string | undefined;

  if (typeof window === "undefined") {
    // 서버 사이드
    accessToken = serverAccessToken;
  } else {
    // 클라이언트 사이드
    accessToken = Cookies.get("accessToken");
  }

  // console.log("accessToken", accessToken, API_URL);
  const headers: HeadersInit = new Headers(options.headers);

  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }

  if (!(options.body instanceof FormData)) {
    headers.set("Content-Type", "application/json");
  }

  const response = await fetch(`${API_URL}`, {
    ...options,
    headers,
  });

  const contentType = response.headers.get("content-type");
  let data;

  if (contentType && contentType.includes("application/json")) {
    data = await response.json();
  } else {
    data = await response.text();
  }

  // console.log(response);

  if (!response.ok) {
    console.error(data);
    throw new Error(data.message || "API 요청 중 오류가 발생했습니다.");
  }

  return data;
};

// export async function fetchWithErrorHandling<T>(
//   url: string,
//   options: FetchOptions = {},
// ): Promise<ApiResponse<T>> {
//   const { accessToken, ...fetchOptions } = options;
//
//   const headers = new Headers(fetchOptions.headers);
//   if (accessToken) {
//     headers.set("Authorization", `Bearer ${accessToken}`);
//   }
//
//   const response = await fetch(url, {
//     ...fetchOptions,
//     headers,
//   });
//
//   if (response.ok) {
//     const data = await response.json();
//     return {
//       result: true,
//       status: response.status,
//       message: "Success",
//       data,
//     };
//   }
//
//   throw new Error(`API request failed with status ${response.status}`);
// }

export default fetchAPI;
