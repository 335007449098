import ShoppingCartContents from "./ShoppingCartContents";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/tooltip";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@repo/ui/components/ui/sheet";
import { ShoppingCart } from "@repo/ui/icons";
import { useState } from "react";

const ShoppingCartPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger className="w-full">
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger className="w-full">
              <button className={`sidebar-item-link items-center w-full`}>
                <div className={`flex flex-col items-center`}>
                  <ShoppingCart />
                </div>
              </button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Your Orders</SheetTitle>
                <SheetDescription>
                  <ShoppingCartContents onClose={() => setIsOpen(false)} />
                </SheetDescription>
              </SheetHeader>
            </SheetContent>
          </Sheet>
        </TooltipTrigger>
        <TooltipContent
          side="right"
          className="bg-primary-500 text-white border-none"
        >
          <span className="text-md font-medium">Cart</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default ShoppingCartPopup;
