"use client";

import React from "react";
import { useRouter, useSearchParams } from "next/navigation";

const RedirectButton = ({
  icon,
  content,
  url,
  onClick,
}: {
  icon: React.ReactNode;
  content: string;
  url: string;
  onClick?: () => void;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  return (
    <button
      className="flex items-center justify-center gap-2 rounded-lg bg-primary-500 px-4 py-2 text-white shadow-xl transition duration-300 hover:scale-110 hover:bg-primary-600 active:scale-105 active:bg-primary-700"
      onClick={() => {
        router.push(`/request-fields?${searchParams.toString()}`, {
          scroll: false,
        });
        onClick && onClick();
      }}
    >
      {icon}
      <span className="font-semibold">{content}</span>
    </button>
  );
};

export default RedirectButton;
