'use client'

import useTogglePopup from '../../../hooks/useTogglePopup'
import { Loader } from '@repo/common/Loading'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter, useSearchParams } from 'next/navigation'
import React, { Suspense, useEffect, useState } from 'react'

import { notificationComponent } from './Notification.component'
import useNotifications from '@/common/hooks/useNotifications'
import useMapStore from '@/common/store/map'
import useSidebarStore from '@/common/store/sidebar'
import useUserStore from '@/common/store/user'

const NotificationContainer = () => {
  const { isNotiAllChecked, setIsNotiAllChecked, setIsSecondSidebarClosed } =
    useSidebarStore()

  // const { user } = useUserStore()
  const { clearSelectedImages, clearSelectedLumirImages } = useMapStore()
  const {
    isOpen: isNotiOpen,
    toggle: setIsNotiOpen,
    innerRef: notiButtonRef,
    outerRef: notiRef,
  } = useTogglePopup()

  const router = useRouter()
  const searchParams = useSearchParams()
  const queryClient = useQueryClient()

  // const { data, isLoading } = useNotifications(user?.userId)

  const data = null
  const isLoading = false
  // console.log(data);

  const [notifications, setNotifications] = useState([])
  // console.log(notifications)

  useEffect(() => {
    if (data && data.data) {
      const filteredNotifications = data.data.filter(
        (notification) => !notification.isFinished
      )
      setNotifications(filteredNotifications)
    }
  }, [data]) // data가 변경될 때마다 이 효과를 다시 실행

  // const handleNotiButtonClick = async (e: React.MouseEvent<HTMLDivElement>) => {
  //   const params = new URLSearchParams(searchParams.toString())
  //   params.set('tab', 'all')

  //   e.stopPropagation()
  //   setIsNotiOpen(!isNotiOpen)

  //   await queryClient.invalidateQueries({
  //     queryKey: [
  //       `${process.env.NEXT_PUBLIC_MAIN_SERVER_API_URL}/api/satellites/subscribe/user?user_id=${user?.userId}`,
  //     ],
  //   })
  // }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Suspense fallback={<Loader style="size-5" />}>
      <div
        ref={notiButtonRef}
        className={`sidebar-item-link flex cursor-pointer flex-col items-center ${isNotiOpen ? 'sidebar-item-link-active' : ''} relative outline-none`}
        onClick={(e) => {
          const params = new URLSearchParams(searchParams.toString())
          params.set('tab', 'all')

          e.stopPropagation()
          setIsNotiOpen()
        }}
        // onClick={handleNotiButtonClick}
      >
        {notificationComponent.Button()}
        {notificationComponent.Content({
          router,
          setIsSecondSidebarClosed,
          isNotiOpen,
          notiRef,
          setIsNotiOpen,
          isNotiAllChecked,
          setIsNotiAllChecked,
          notifications,
          clearSelectedImages,
          clearSelectedLumirImages,
        })}
      </div>
    </Suspense>
  )
}

export default NotificationContainer
