import useMapStore from "@/common/store/map";

const useClearMapData = () => {
  const { clearLayers, clearSelectedImages, clearSelectedLumirImages } =
    useMapStore();

  const handleClearImagesAndLayers = () => {
    clearLayers();
    clearSelectedImages();
    clearSelectedLumirImages();
  };

  return handleClearImagesAndLayers;
};

export default useClearMapData;
