"use client";

import React from "react";
import SideNavigationItem from "./SideNavigationItem";
import { Separator } from "@repo/ui/components/ui/separator";

const SideNavigationItems = ({ sidebarItems }) => {
  return (
    <div>
      <div className="px-2">
        <Separator className="bg-gray-700" />

        <div className="flex flex-col gap-2 py-4">
          {sidebarItems.map((sidebarItem, index) => (
            <SideNavigationItem key={index} sidebarItem={sidebarItem} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideNavigationItems;
