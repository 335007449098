import { create } from "zustand";
import { persist } from "zustand/middleware";

interface SidebarState {
  isSidebarClosed: boolean;
  isNotiAllChecked: boolean;
  isSecondSidebarClosed: boolean;
  shouldOpenProgressBar: boolean;
  currentSideBarOption: string | null;
  clickedSideBarOptions: string[];
  dataFetching: boolean;
}

interface SidebarActions {
  toggleSidebarClosed: () => void;
  toggleSecondSidebarClosed: () => void;
  setIsSidebarClosed: (open: boolean) => void;
  setIsSecondSidebarClosed: (open: boolean) => void;
  setIsNotiAllChecked: (checked: boolean) => void;
  setShouldOpenProgressBar: (open: boolean) => void;
  toggleSideBarOpened: () => void;
  setCurrentSideBarOption: (currentSideBarOption: string) => void;
  pushCurrentSideBarOption: (currentSideBarOption: string) => void;
  setDataFetching: (fetching: boolean) => void;
}

type SidebarStore = SidebarState & SidebarActions;

const useSidebarStore = create<SidebarStore>()(
  persist(
    (set): SidebarStore => ({
      isSidebarClosed: false,
      isNotiAllChecked: false,
      isSecondSidebarClosed: true,
      shouldOpenProgressBar: false,
      toggleSidebarClosed: () =>
        set((state) => ({
          isSidebarClosed: !state.isSidebarClosed,
        })),
      toggleSecondSidebarClosed: () =>
        set((state) => ({
          isSecondSidebarClosed: !state.isSecondSidebarClosed,
        })),
      setIsSidebarClosed: (open) => {
        set({ isSidebarClosed: open });
      },
      setIsSecondSidebarClosed: (open) => {
        set({ isSecondSidebarClosed: open });
      },
      setIsNotiAllChecked: (checked) => {
        set({ isNotiAllChecked: checked });
      },

      setShouldOpenProgressBar: (shouldOpen) => {
        set({ shouldOpenProgressBar: shouldOpen });
      },

      currentSideBarOption: null,
      clickedSideBarOptions: [],
      toggleSideBarOpened: () =>
        set((state) => {
          return {
            isSidebarClosed: !state.isSidebarClosed,
            currentSideBarOption: null,
          };
        }),
      setCurrentSideBarOption: (currentSideBarOption) =>
        set({
          currentSideBarOption,
          isSidebarClosed: false,
        }),
      pushCurrentSideBarOption: (currentSideBarOption) =>
        set((state) => ({
          clickedSideBarOptions: [
            ...state.clickedSideBarOptions,
            currentSideBarOption,
          ],
        })),

      dataFetching: false,
      setDataFetching: (fetching) => {
        set({ dataFetching: fetching });
      },
    }),
    {
      name: "sidebar-store",
      partialize: (state) => {
        const { dataFetching, ...rest } = state;
        return rest;
      },
    },
  ),
);

export default useSidebarStore;
