"use client";

import React from "react";

import Image from "next/image";
import Link from "next/link";
import { PLATFORM_ROUTES } from "@/routes/app.routes";
// import LumirLogo from "~/assets/logo.webp";
import LumirWhiteLogo from "~/assets/lumir-logo-white-no-text.png";
import useRestoreSidebar from "../hooks/useRestoreSidebar";
import { redirect } from "next/navigation";

const SideNavigationLogo = () => {
  // 사이드바 복원 핸들러
  const restoreSidebar = useRestoreSidebar();
  // 로고 클릭 핸들러
  const handleLogoClick = () => {
    restoreSidebar();
    redirect("/");
  };
  return (
    <div className="inline-flex size-16 items-center justify-center">
      <Link
        href={PLATFORM_ROUTES.REQUEST_FIELDS.href}
        scroll={false}
        onClick={handleLogoClick}
      >
        <Image src={LumirWhiteLogo} width={40} height={40} alt="lumir logo" />
      </Link>
    </div>
  );
};

export default SideNavigationLogo;
