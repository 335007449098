"use client";

import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";

const TextTabs = ({ tabItems }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const currentTab = searchParams.get("tab") || "all";

  const isActive = (tab: string) => {
    return currentTab === tab;
  };

  return (
    <div className="hidden sm:flex sm:min-w-[536px]">
      <div className="flex-1 border-b border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {tabItems.map(({ title }) => {
            const lowerCase = title.toLowerCase().replace(/\s+/g, "-");

            // 새로운 searchParams 객체를 생성하여 기존 쿼리를 복사합니다.
            const newSearchParams = new URLSearchParams(
              searchParams.toString()
            );
            // 'tab' 파라미터의 값을 새 값으로 설정합니다.
            newSearchParams.set("tab", lowerCase);
            // 새로운 쿼리 스트링을 생성합니다.
            const newQueryString = newSearchParams.toString();

            return (
              <Link
                key={title}
                href={`${pathname}?${newQueryString}`}
                className={`notification-tab-link ${isActive(lowerCase) ? "notification-tab-link-active" : ""}`}
                scroll={false}
              >
                {title}
              </Link>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default TextTabs;
