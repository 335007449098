import { Category, UserInfoResponse } from '@repo/common/dto'
import { create } from 'zustand'

import { LogoutResponse } from '@/app/api/auth/logout/route'

import fetchAPI from '@/helpers/fetch'

interface UserState {
  user: {
    image: string
    name: string
    email: string
    userId: string
  } | null
  selectedCategory: Category | null
  isLoading: boolean
  setSelectedCategory: (category: Category) => void
  fetchUserInfo: () => Promise<void>
  logout: () => Promise<void>
}

const useUserStore = create<UserState>((set) => ({
  user: null,
  selectedCategory: null,
  isLoading: false,
  setSelectedCategory: (category: Category) => {
    set({ selectedCategory: category })
  },
  fetchUserInfo: async () => {
    set({ isLoading: true })
    try {
      const res = await fetchAPI<UserInfoResponse>('/api/auth/user-info')
      // console.log(res)
      set({
        user: {
          image:
            res.data.items[0].image ||
            'https://randomuser.me/api/portraits/men/28.jpg',
          name: res.data.items[0].name || 'John Doe',
          email: res.data.items[0].email || '',
          userId: res.data.items[0].userId || '',
        },
        isLoading: false,
      })
    } catch (error) {
      console.error('Failed to fetch user info:', error)
      set({ user: null, isLoading: false })
    }
  },
  logout: async () => {
    const response = await fetchAPI<LogoutResponse>('/api/auth/logout', {
      method: 'POST',
    })
    if (response.success) {
      set({ user: null })
      window.location.href = '/'
    } else {
      console.error('Logout failed')
    }
  },
}))

export default useUserStore
