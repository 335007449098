import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/tooltip";
import { useState } from "react";
import { IconLoader2 } from "@tabler/icons-react";

const SideNavigationRouteToggle = ({
  sidebarItem,
  // toggleEarthquake,
  // setToggleEarthquake,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (e) => {
    if (sidebarItem.disabled) {
      e.preventDefault();
      return;
    }
    setIsLoading(true);
  };

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger className="w-full">
          <a
            href={sidebarItem.disabled ? "#" : sidebarItem.href}
            className={`w-full sidebar-item-link items-center`}
            onClick={handleClick}
          >
            <button
              disabled={sidebarItem.disabled}
              className={`flex flex-col items-center ${sidebarItem.disabled ? "pointer-events-none" : ""}`}
            >
              {isLoading ? (
                <IconLoader2 className="h-5 w-5 animate-spin" />
              ) : (
                sidebarItem.icon
              )}
            </button>
          </a>
        </TooltipTrigger>
        <TooltipContent
          side="right"
          className="bg-primary-500 text-white border-none"
        >
          <span className="text-md font-medium">{sidebarItem.title}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default SideNavigationRouteToggle;
