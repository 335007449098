import { IconLoader2 } from '@tabler/icons-react'
import React, { useEffect } from 'react'

import NotificationContainer from '@/common/components/SideNavigation/components/Notification/components/Notification.container'
import SideNavigationProfile from '@/common/components/SideNavigation/components/SideNavigationProfile'
import useUserStore from '@/common/store/user'

const SideNavigationUserContent = () => {
  const { user, isLoading, fetchUserInfo, logout } = useUserStore()

  useEffect(() => {
    fetchUserInfo()
    console.log(user)
  }, [fetchUserInfo])

  if (isLoading) {
    return <IconLoader2 className="size-6 animate-spin text-gray-300" />
  }

  if (!user) {
    return null // 또는 로그인되지 않은 상태를 나타내는 컴포넌트를 렌더링
  }

  return (
    <>
      <NotificationContainer />
      <SideNavigationProfile user={user} handleLogout={logout} />
    </>
  )
}

export default SideNavigationUserContent
