"use client";

import React from "react";
import SideNavigationUserInfo from "./SideNavigationUserInfo";
import { Loader } from "@repo/common/Loading";

const SideNavigationProfile = ({ user, handleLogout }) => {
  if (!user) {
    return <Loader style="size-5" />;
  }

  return (
    <div className="flex flex-col items-center gap-2 w-full">
      <SideNavigationUserInfo handleLogout={handleLogout} user={user} />
    </div>
  );
};

export default SideNavigationProfile;
