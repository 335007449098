import { NotificationPersistance } from "../repository/Notification.repository";
import { INotificationService } from "./Notification.service.interface";

export class NotificationService implements INotificationService {
  persistance: NotificationPersistance;

  constructor() {
    this.persistance = new NotificationPersistance();
  }

  getTimeAgo = (dateString: string) => {
    const koreaTimeOffset = 9 * 60 * 60 * 1000; // KST, UTC+9
    const nowUTC = new Date().getTime();
    const createdAtUTC = new Date(dateString).getTime();

    const nowKST = new Date(nowUTC);
    const createdAtKST = new Date(createdAtUTC + koreaTimeOffset);
    const diffInMilliseconds =
      koreaTimeOffset + nowKST.getTime() - createdAtKST.getTime();

    const seconds = diffInMilliseconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    if (days >= 1) {
      return `${Math.floor(days)} days ago`;
    } else if (hours >= 1) {
      return `${Math.floor(hours)} hours ago`;
    } else if (minutes >= 1) {
      return `${Math.floor(minutes)} minutes ago`;
    } else {
      return `just now`;
    }
  };
}
