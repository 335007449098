import { usePathname } from "next/navigation";

import { PLATFORM_ROUTES, historyTabs, homeTabs } from "@repo/common/constants";

const useIsActivePage = () => {
  const pathname = usePathname();

  const isActivePage = (page: string) => {
    switch (page) {
      case "home":
        return (
          pathname === "/" ||
          homeTabs.some((tab) => pathname === tab.url) ||
          pathname.startsWith(PLATFORM_ROUTES.SEARCHED_DATA.href)
        );
      case "history":
        return (
          pathname.startsWith("/history") ||
          historyTabs.some((tab) => pathname === tab.url)
        );
      default:
        return pathname === `/${page}` || pathname.startsWith(`/${page}/`);
    }
  };

  return isActivePage;
};

export default useIsActivePage;
